<script>
export default {
  metaInfo: {
    title: "Explorers",
  }
};
</script>

<script setup>
import { ref, computed, defineProps, onBeforeMount, inject } from "vue";
import UiBasicTableLayout from "@/components/basic/layouts/UiBasicTableLayout.vue";
import ExplorerHwBrand from "@/components/specific/Explorer/ExplorerHwBrand.vue";
import ClickableText from "@/components/basic/ClickableText.vue";
import UiTable from "@/components/basic/tables/UiTable.vue";
import UiSmHistoryTable from "@/components/basic/tables/UiSmHistoryTable.vue";
import ExplorerStatus from "@/components/specific/Explorer/ExplorerStatus.vue";
import LazyLoadingSkeleton from "@/components/specific/LazyLoadingSkeleton.vue";
import ExplorerStatusChart from "@/components/specific/ExplorerStatusChart.vue";
import SplitButton from "@/components/basic/SplitButton.vue";
import XBtn from "@/components/basic/XBtn.vue";
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import XSelect from "@/components/basic/XSelect.vue";
import XCheckbox from "@/components/basic/XCheckbox.vue";
import UiTableItemActions from "@/components/basic/tables/UiTableItemActions.vue"
import TagQueryTextField from "@/components/extended/TagQueryTextField.vue";
import XTextField from "@/components/basic/XTextField.vue";
import LoadingDialog from "@/components/basic/LoadingDialog.vue";

import { cockpitExplorerService } from "@/api";
import { ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings"
import { useExplorersTable, TABLE_COLUMNS, TABLE_ACTIONS } from "@/composition/explorers/use-explorers-table";
import { useExplorers } from "@/composition/explorers/use-explorers";
import { useExplorersStatistics } from "@/composition/explorers/use-explorers-statistics";
import { useExplorerCsv } from "@/composition/explorers/use-explorers-csv";
import { useStore } from "@/store";
import { useTableHistory } from "@/composition/tables/use-table-history";
import { useRoles } from "@/composition/user/use-roles";
import { debounce } from "lodash-es";

const REFRESH_MS = 30000
const STATISTIC_RANGE_HOUR = 25;

const props = defineProps({
  page: {
    type: Number,
    default: 1
  },
  itemsPerPage: {
    type: Number,
    default: 25
  },
  from: {
    type: Number,
    //01.01.1970
    default: Math.trunc(new Date(0).getTime() / 1000),
  },
  to: {
    type: Number,
    // tomorrow
    default: Math.trunc(new Date(new Date().setHours(24, 0, 0, 0)).getTime() / 1000),
  },
  search: {
    type: String,
    default: ""
  },
  sortBy: {
    type: String,
    default: ""
  },
  descending: {
    type: Boolean,
    default: false
  },
  full: {
    type: Boolean,
    default: false
  },
  historyFor: {
    type: Number,
    default: 0
  },
  status: {
    type: [String, Number],
    default: -1,
  },
  tags: {
    type: String,
    default: "",
  },
  online: {
    type: String,
    default: "",
  },
  devicePool: {
    type: [String, Number],
    default: 0,
  },
});

const store = useStore();

const {
  isAdmin,
  isManager,
  isSuperAdmin,
} = useRoles();

const {
  getHistoryCols,
} = useTableHistory();

const { downloadExplorersCsv, isCsvLoading } = useExplorerCsv({ title: "Explorers" });

const {
  init,
  updateExplorersTable,
  updateExplorerInTable,
  isLoading,
  explorersList,
  params,
  updateParams,
  meta,
  statusTotal,
  onlineFilter,
  selectTotalStatuses: _selectTotalStatuses,
  statusesCounts,
  statusesRepr,
  businessStatusList,
  devicePools,
  clearFileters,
  updateTableData,
} = useExplorersTable({ params: props, refreshMs: REFRESH_MS });

const debouncedUpdateParams = debounce(updateParams, 300);

const selectTotalStatuses = () => {
  _selectTotalStatuses();
  updateParams({ ...params.value, online: [] });
};

const {
  useExplorerAlias,
  checkDeactivatedExplorer,
  toggleExplorer,
  isTogglingExplorer,
  rebootExplorer,
  isRebootingExplorer,
  deleteExplorer,
  isDeletingExplorer,
} = useExplorers();

const {
  isOthersStatistics,
  isProjectStatistics,
  getExplorerTestsStatistic,
  statisticsContextReqParam,
  fromMs,
  toMs,
} = useExplorersStatistics({ rangeInMs: STATISTIC_RANGE_HOUR * 60 * 60 * 1000 });

const {
  openDialog
} = inject("DialogsRoot")

const openExplorerDialog = async ({ id }) => {
  const { isModified } = await openDialog("ExplorerDialog", { explorerId: id, value: true })
  if (!isModified) {
    return;
  }
  const updatedExplorer = await cockpitExplorerService.v2.getExplorer(id, { version: 0 });
  updateExplorerInTable(id, updatedExplorer);
};

const openDialogWidthDevicePools = async (explorerIds) => {
  const { isAssigned } = await openDialog("AssignToDevicePoolDialog", { explorerIds, value: true });
  if (isAssigned) {
    await updateExplorersTable(params.value, { ignoreCache: true });
  }
};

const openImportExplorersDialog = async () => {
  await openDialog("ExplorerImportDialog");
};

const openGetExplorerDialog = async () => {
  await openDialog("GetExplorerDialog", { value: true });
};

const selectedExplorers = ref({});
const selectedExplorersIds = computed(() => {
  return Object.keys(selectedExplorers.value);
})

const toggleExplorerSelection = (explorerId) => {
  selectedExplorers.value = {
    ...selectedExplorers.value,
    [explorerId]: !selectedExplorers.value[explorerId]
  };
  
  if (!selectedExplorers.value[explorerId]) {
    const { [explorerId]: removed, ...rest } = selectedExplorers.value;
    selectedExplorers.value = rest;
  }
};

const handleSelectAll = (checked) => {
  if (checked) {
    const newSelected = {};
    for (const e of explorersList.value) {
      newSelected[e.id] = true;
    }
    selectedExplorers.value = newSelected;
  } else {
    selectedExplorers.value = {};
  }
};

const isAllCurrentPageSelected = computed(() => {
  return explorersList.value.length > 0 && 
    explorersList.value.every(explorer => !!selectedExplorers.value[explorer.id]);
});

const selectedCount = computed(() => 
  Object.keys(selectedExplorers.value).length
);

const isExplorerViewer = computed(() => store.state.role === 5);


onBeforeMount(async () => {
  await init();
});
</script>

<template>
  <div style="display: contents;">
    <UiBasicTableLayout
      class="explorers-view"
    >
      <template #page-headline>
        <div class="explorers-view__page-head">
          <div class="explorers-view__page-head-side explorers-view__page-head-side--left">
            <XBtn 
              v-if="isManager || isAdmin || isSuperAdmin"
              text="Import CSV"
              color="primary"
              @click="() => openImportExplorersDialog()"
            />

            <XBtn 
              :disabled="isExplorerViewer"
              text="Export as CSV"
              color="primary"
              @click="() => downloadExplorersCsv(params)"
            />

            <XBtn 
              :disabled="isExplorerViewer"
              text="Get Explorer"
              icon="mdi-cloud-download-outline"
              color="primary"
              @click="() => openGetExplorerDialog()"
            />

            <h2 class="explorers-view__page-title">
              Explorers
            </h2>
          </div>

          <div class="explorers-view__page-head-side explorers-view__page-head-side--right">
            <XSelect
              :value="params.status"
              label="Status Filter"
              required
              autocomplete
              item-text="name"
              :items="businessStatusList"
              @input="(statusId) => updateParams({ ...params, status: statusId, page: 1 })"
            />

            <TagQueryTextField
              class="explorers-view__tag-query-text-field"
              id="explorers_tag_query"
              :value="params.tags"
              @input="(tags) => debouncedUpdateParams({ ...params, tags, page: 1 })"
            />

            <XSelect
              label="Device Pool"
              :value="params.devicePool"
              @input="(devicePoolId) => updateParams({ ...params, devicePool: devicePoolId, page: 1 })"
              item-value="id"
              item-text="name"
              :items="devicePools"
            />

            <XTextField
              :value="params.search"
              @input="(s) => debouncedUpdateParams({ ...params, search: s, page: 1 })"
              clearable
              label="Search"
              append-icon="mdi-magnify"
              class="search-text-field"
            />

            <XBtn
              text="Clear Filter"
              icon="mdi-filter-remove"
              color="primary"
              @click="() => clearFileters()"
            />
          </div>
        </div>
      </template>

      <template #before-table>
        <div class="explorers-view__status-bar">
          <XBtn
            class="explorers-view__total-button"
            :text="statusTotal.text"
            height="25"
            :color="onlineFilter.length  ? `${statusTotal.color} lighten-3` : `${statusTotal.color}`"
            elevation="0"
            @click="() => selectTotalStatuses()"
          />

          <SplitButton
            class="explorers-view__statuses-split-btn"
            :value="onlineFilter"
            @input="(_statuses) => updateParams({ ...params, online: _statuses })"
            :values="statusesCounts"
            :splits="statusesRepr"
            :total="statusesCounts?.total || 0"
            multiple
          />
        </div>
      </template>

      <template #table-slot>
        <UiTable
          :items="explorersList"
          :columns="TABLE_COLUMNS"
          :sortBy="params.sortBy"
          :sortDesc="params.descending"
          :is-loading="isLoading"
          :check-disabled-row="(expl) => checkDeactivatedExplorer(expl)"
          @update:sorting="({ sortBy, sortDesc }) => updateParams({ ...params, sortBy, descending: sortDesc })"
        >
          <template #header(testStatistics)="{ col }">
            <div class="explorers-view__th-content explorers-view__th-content--statistics">
              <span class="explorers-view__th-text">
                {{ col.name }}
              </span>

              <div class="explorers-view__th-statistics-checkboxes">
                <XCheckbox
                  label="Project"
                  v-model="isProjectStatistics"
                />

                <XCheckbox
                  label="Other"
                  v-model="isOthersStatistics"
                />
              </div>
            </div>
          </template>

          <template #cell(actions)="{ item }">
            <div class="explorers-view__actions-cell">
              <XCheckbox
                :value="Boolean(selectedExplorers[item.id])"
                @input="() => toggleExplorerSelection(item.id)"
                dense
              />

              <UiTableItemActions
                :actions="TABLE_ACTIONS"
                class="explorers-view__action-box"
              >
                <template #action(explorer-tests)>
                  <router-link
                    :to="{ name: 'explorer-tests', query: { explorerId: item.id } }"
                    class="explorers-view__action-btn"
                  >
                    <span>
                      <v-icon>mdi-table-eye</v-icon>

                      <span class="explorers-view__action-txt">
                        Explorer Tests
                      </span>
                    </span>
                  </router-link>
                </template>

                <template #action(activation)>
                  <button
                    class="explorers-view__action-btn"
                    type="button"
                    :disabled="isExplorerViewer"
                    @click="() => toggleExplorer(item).then(() => updateTableData({ isSilent: true, ignoreCache: true }))"
                  >
                    <template v-if="item.active">
                      <v-progress-circular
                        v-if="isTogglingExplorer"
                        indeterminate
                        color="primary"
                        size="24"
                        width="3"
                      />

                      <v-icon v-else>mdi-toggle-switch</v-icon>

                      <span class="explorers-view__action-txt">
                        Deactivate Explorer
                      </span>
                    </template>

                    <template v-else>
                      <v-progress-circular
                        v-if="isTogglingExplorer"
                        indeterminate
                        color="primary"
                        size="24"
                        width="3"
                      />

                      <v-icon v-else>mdi-toggle-switch-off</v-icon>

                      <span class="explorers-view__action-txt">
                        Activate Explorer
                      </span>
                    </template>
                  </button>
                </template>

                <template #action(reboot)>
                  <button
                    class="explorers-view__action-btn"
                    type="button"
                    :disabled="isExplorerViewer"
                    @click="() => rebootExplorer(item).then(() => updateTableData({ isSilent: true, ignoreCache: true }))"
                  >
                    <span>
                      <v-progress-circular
                        v-if="isRebootingExplorer"
                        indeterminate
                        color="primary"
                        size="24"
                        width="3"
                      />

                      <v-icon v-else>mdi-restart</v-icon>

                      <span class="explorers-view__action-txt">
                        Reboot Explorer
                      </span>
                    </span>
                  </button>
                </template>

                <template #action(assign-device-pool)>
                  <button
                    class="explorers-view__action-btn"
                    type="button"
                    :disabled="isExplorerViewer"
                    @click="() => openDialogWidthDevicePools([item.id])"
                  >
                    <span>
                      <v-icon>mdi-view-grid-plus</v-icon>

                      <span class="explorers-view__action-txt">
                        Assign to Device Pool
                      </span>
                    </span>
                  </button>
                </template>

                <template
                  v-if="isAdmin || isSuperAdmin || isManager"
                  #action(history)
                >
                  <router-link
                    class="explorers-view__action-btn"
                    type="button"
                    :to="{ name: 'explorers-history', params: { id: item.id } }"
                  >
                    <span>
                      <v-icon>mdi-history</v-icon>

                      <span class="explorers-view__action-txt">
                        History
                      </span>
                    </span>

                    <v-tooltip right>
                      <template #activator="{on, attrs}">
                        <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                      </template>

                      <UiSmHistoryTable
                        :history="item.history"
                        :cols="getHistoryCols(item.history)"
                      />
                    </v-tooltip>
                  </router-link>
                </template>

                <template #action(delete)>
                  <button
                    class="explorers-view__action-btn explorers-view__action-btn--delete"
                    type="button"
                    :disabled="isExplorerViewer"
                    @click="() => deleteExplorer(item).then(() => updateTableData({ isSilent: true, ignoreCache: true }))"
                  >
                    <span>
                      <v-progress-circular
                        v-if="isDeletingExplorer"
                        indeterminate
                        color="primary"
                        size="24"
                        width="3"
                      />

                      <v-icon 
                        v-else
                        class="explorers-view__delete-icon"
                      >
                        mdi-delete
                      </v-icon>

                      <span class="explorers-view__action-txt">
                        Delete
                      </span>
                    </span>
                  </button>
                </template>
              </UiTableItemActions>
            </div>
          </template>

          <template #cell(name)="{ item: expl, cellValue }">
            <div class="explorers-view__explorer-name">
              <ExplorerHwBrand
                :degust-image-version="expl.systemInfo.systemType.degustImageVersion"
                size="24"
              />

              <template v-if="!useExplorerAlias || !expl['alias']">
                <ClickableText
                  :text="cellValue"
                  @click="() => openExplorerDialog({ id: expl.id })"
                />

                <div v-if="expl['alias']">
                  ({{ expl["alias"] }})
                </div>
              </template>

              <template v-else>
                <ClickableText
                  :text="expl['alias']"
                  @click="() => openExplorerDialog({ id: expl.id })"
                />

                <div>
                  ({{ cellValue }})
                </div>
              </template>
            </div>
          </template>

          <template #cell(systemInfo-systemType-networkInterfaces-eth0)="{ cellValue }">
            {{ cellValue || "" }}
          </template>

          <template #cell(status)="{ cellValue }">
            <ExplorerStatus :value="cellValue"/>
          </template>

          <template #cell(description)="{ cellValue }">
            <p class="explorers-view__description">
              {{ cellValue }}
            </p>
          </template>

          <template #cell(tags)="{ cellValue: tagList }">
            <div class="explorers-view__tags">
              <ClickableText 
                v-for="(tag, i) of tagList.filter(t => Boolean(t.name.trim()))"
                :key="i"
                :text="tag.name"
                @click="() => updateParams({ ...params, tags: tag.name })"
              />
            </div>
          </template>

          <template #cell(testStatistics)="{ item }">
            <p
              v-if="!item.active"
              class="explorers-view__chart-txt"
            >
              Explorer is deactivated.
            </p>

            <p
              v-else-if="item.type === 'sim-server'"
              class="explorers-view__chart-txt"
            >
              Not used for testing.
            </p>

            <LazyLoadingSkeleton
              v-else-if="item.active && isProjectStatistics || isOthersStatistics"
              :key="`${item.id}-${statisticsContextReqParam}`"
              class="explorers-view__lazy-charts"
              :data-fetcher="() => getExplorerTestsStatistic(item.id)"
              :refresh-sec="REFRESH_MS / 1000"
              :debounce-ms="1000"
            >
              <template #default="{ data }">
                <ExplorerStatusChart
                  :statistics-data="data"
                  :chart-height="80"
                  :to-ms="toMs"
                  :from-ms="fromMs"
                  :echart-options="{ xAxis: { axisLine: { show: false } } }"
                />
              </template>
            </LazyLoadingSkeleton>

            <p
              v-else-if="item.active"
              class="explorers-view__chart-txt"
            >
              Please select a context
            </p>
          </template>
        </UiTable>
      </template>

      <template #page-footer>
        <div
          v-if="meta.page"
          class="explorers-view__footer"
        >
          <div class="explorers-view__footer-actions">
            <XCheckbox
              label="Select All"
              :value="isAllCurrentPageSelected"
              @input="(checked) => handleSelectAll(checked)"
              dense
            />

            <ClickableText
              text="Assign to Device Pool"
              icon="mdi-view-grid-plus"
              icon-color=""
              :disabled="!Object.values(selectedExplorers).includes(true) || isExplorerViewer"
              @click="() => openDialogWidthDevicePools(selectedExplorersIds)"
            />

            <span 
              v-if="selectedCount > 0"
              class="explorers-view__selected-num"
            >
              {{ selectedCount }} selected
            </span>
          </div>

          <div class="explorers-view__pagination">
            <PaginationBlock
              :total-count="meta.totalCount"
              :current-page="meta.page"
              :items-per-page="meta.itemsPerPage"
              @update:current-page="(pageN) => updateParams({ ...params, page: pageN })"
            />

            <XSelect
              class="explorers-view__items-per-page-select"
              :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
              :value="meta.itemsPerPage"
              @input="(_iPerPage) => updateParams({ ...params, itemsPerPage: _iPerPage})"
              label="Items per page"
              :autocomplete="false"
            />
          </div>
        </div>
      </template>
    </UiBasicTableLayout>

    <LoadingDialog 
      v-if="isCsvLoading"
      value
    />
  </div>
</template>

<style lang="scss">
:root {
  --v-application-wrap-display: grid;
  --v-application-wrap-overflow: hidden;
  --main-v3-max-height: initial;
}

.explorers-view {
  $root: &;

  &__page-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__page-title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: .0125rem;
    line-height: 2rem;
  }

  &__page-head-side {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__explorer-name {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }

  &__tags {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
  }

  &__lazy-charts {
    height: 80px;
  }

  &__chart-txt {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #666666;
  }

  &__total-button {
    flex: 0 0 auto;

    .v-btn:not(.v-btn--round).v-size--default {
      color: var(--v-text-inverted-base);
      font-size: 12px;
    }
  }

  &__status-bar {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
  }

  &__statuses-split-btn {
    flex: 1;
  }

  & &__description,
  & &__chart-txt {
    margin-bottom: 0;
  }

  & &__chart-txt {
    text-align: center;
    width: 100%;
  }

  &__th-content {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    &--statistics {
      justify-content: space-between;
    }
  }

  &__th-text {
    color: var(--v-tableHeader-base);
    font-weight: 700;
    user-select: none;
  }

  &__th-statistics-checkboxes {
    display: flex;
    gap: .5rem;
    font-weight: 400;
  }

  &__actions-cell {
    display: flex;
    gap: .5rem;

    .v-input--selection-controls__input {
      margin-right: 0.25rem !important;
    }
  }

  &__action-btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: .25rem;
    width: 100%;
    padding: 0.875rem 1rem;
    font-size: 1rem;

    &--delete {
      width: 100%;
      color: rgba(0,0,0,.54);

      &:hover {
        color: white;
        background-color: #b82519;

        #{$root}__delete-icon {
          color: white;
        }

        #{$root}__action-txt {
          color: white;
        }
      }
    }
  }

  &__action-txt {
    text-wrap: nowrap;
    color: #000;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  &__footer-actions {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  &__selected-num {
    color: rgba(0,0,0,.6);
  }

  &__pagination {
    display: flex;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }
}
</style>
